import React, { useEffect, useState } from "react";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import { Card, CardContent, Typography } from "@mui/material";
import { setAuthorizationHeader, get, post } from "../config/api";
import {
  ArrowBack,
  ArrowForwardIos,
  CurrencyExchangeOutlined,
  GpsFixed,
  GpsFixedOutlined,
  HomeOutlined,
  Money,
  PlusOne,
  Timer,
  Timer10,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { border, borderRadius, borderTop, borderTopColor } from "@mui/system";
import ApexChart from "../components/ApexChart";
import AvcBarChart from "../components/ApexChart";

const appBarStyle = {
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "row",
  background: "#007bff",
  color: "#fff",
  width: "100%",
  marginBottom: "30px",
  // marginTop: "20px",
  alignItems: "center",
  padding: "10px",
  borderRadius: "8px",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
};

const secondaryBarStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "absolute",
  top: "27%",
  left: "7%",
  background: "#007bff",
  padding: "5px",
  color: "white",
  borderTop: "2px solid white",
  borderRadius: "0 0 8px 8px",
  
};

const secondaryBarStyle2 = {
  display: "flex",
  justifyContent: "center",
  position: "absolute",
  top: "27%",
  right: "1.9%",
   alignItems: "center",
  background: "#007bff",
  padding: "5px",
  borderRadius: "0 0 8px 8px",
  color: "white",
  borderTop: "2px solid white",
};

const iconStyle = {
  fontSize: "24px",
  cursor: "pointer",
  color: "white",
};

const titleStyle = {
  flex: 1,
  textAlign: "center",
  margin: "0 15px",
  color: "#fff",
};
const Actions = () => {
  const { t, i18n } = useTranslation();
  const { activityId, sectorName, activityName } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  const clickedActivity = state ? state.clickedActivity : null;

  const [actions, setActions] = useState([]);
  const [avc, setAvc] = useState([]);
  const [avcAverage, setAvcAverage] = useState(0);

  const fetchActions = async () => {
    const accessToken = localStorage.getItem("accessToken");
    setAuthorizationHeader(accessToken);
    try {
      const endpoint = `api/v1/action/activity/${activityId}`;
      const response = await get(endpoint);
      setActions(response);
    } catch (error) {
      console.error("Failed to fetch  :", error.message);
    }
  };

  const fetchAVCs = async () => {
    const accessToken = localStorage.getItem("accessToken");
    setAuthorizationHeader(accessToken);
    try {
      const endpoint = `api/v1/avcs/${activityId}`;
      const response = await get(endpoint);
      console.log(response);
      setAvc(response);

      if (response.length > 0) {
        const totalPercentage = response.reduce(
          (acc, avc) => acc + avc.percentage,
          0
        );
        const average = totalPercentage;
        const resp = await post(`api/v1/activity/${activityId}/actions/percentage?percentage=${average}`);

        setAvcAverage(average);
      }
    } catch (error) {
      console.error("Failed to fetch  :", error.message);
    }
  };

  function calculatePercentage(startDateStr, endDateStr) {
    if (!startDateStr || !endDateStr) {
      return t("DNP");
    }
    const startDate = new Date(startDateStr);
    const endDate = new Date(endDateStr);
    const currentDate = new Date();

    const totalMilliseconds = endDate - startDate;
    const elapsedMilliseconds = currentDate - startDate;

    const percentage = (elapsedMilliseconds / totalMilliseconds) * 100;

    return percentage > 100 ? 100 : percentage.toFixed(1);
  }

  function calculateAmountPercentage(currentAmount, totalAmount) {
    const percentage = (currentAmount * 100) / totalAmount;

    return percentage.toFixed(2);
  }

  const goBack = () => {
    navigate(-1);
  };
  useEffect(() => {
    if (localStorage.getItem("accessToken") === "") {
      navigate("/");
    }
    fetchActions();
    fetchAVCs();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "0 0 30px 0",
      }}
    >
      <div style={appBarStyle}>
        <ArrowForwardIos
          style={{ ...iconStyle, transform: "rotate(180deg)" }}
          onClick={goBack}
        />
        <Typography variant="h6" gutterBottom>
          {t(sectorName)} -{" "}
          {i18n.language === "ar"
            ? clickedActivity.shortLabelArabic
            : clickedActivity.shortLabel}
          {clickedActivity.pourcentageTA}
        </Typography>
        {/* <Link to="/dashboard" style={{ textDecoration: "none" }}> */}
        <Typography
          variant="h6"
          gutterBottom
          style={{
            fontFamily: "Digital-7",
            textAlign: "center",
            border: "2px solid #fff",
            padding: "8px 16px",
            display: "flex",
            borderRadius: "10px",
          }}
        >
          {avcAverage > 0
            ? avcAverage.toFixed(1)
            : clickedActivity.pourcentage.toFixed(1)}
          %
        </Typography>

        {/* </Link> */}
      </div>

      <div style={secondaryBarStyle}>
        <div
          style={{
            width: "20px",
            height: "20px",
            borderRadius: "50%",
              marginRight: "8px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Timer />
        </div>
        <Typography
          variant="h6"
          gutterBottom
          style={{
            fontSize: "16px",
            marginTop:"4.5px"
          }}
        >
          {t("TVPD")} |
        </Typography>

        <Typography
          variant="subtitle1"
          style={{
            color: "#fff",
            fontSize: "18px",
            fontFamily: "Digital-7",
            marginLeft: "3px",
          }}
        >
          {calculatePercentage(
            clickedActivity.startDate,
            clickedActivity.endDate
          )}
          {"%"}
        </Typography>
      </div>
      <div style={secondaryBarStyle2}>
      <div
          style={{
            width: "18px",
            height: "18px",
            borderRadius: "50%",
              marginRight: "8px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CurrencyExchangeOutlined />
        </div>
        <Typography
          variant="h6"
          gutterBottom
          style={{
            fontSize: "16px",
            marginTop:"4.5px"
          }}
        >
          {t("decaissement")} |
         
        </Typography>
        <Typography
          variant="subtitle1"
          style={{
            color: "#fff",
            fontSize: "18px",
            fontFamily: "Digital-7",
            marginLeft: "3px",
          }}
        >
           {calculateAmountPercentage(
            clickedActivity.decaissement,
            clickedActivity.montantMarche
          )}
          {"%"}
        </Typography>
      </div>

      <AvcBarChart avcs={avc} activityId={activityId} fetchAVC={fetchAVCs} />

      <div
        style={{
          display: "flex",
          gap: 20,
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          mmarginTop: "20px",
          width: "100%",
        }}
      >
        {actions
          .sort((a, b) => b.pourcentage - a.pourcentage)
          .map((action, index) => (
            <Link
              key={index}
              to={{
                pathname: `/action/${action.id}`,
                state: { total: 30 },
              }}
              style={{ textDecoration: "none" }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  background: "#E5E7E9",
                  padding: "8px",
                  borderRadius: "8px",
                }}
              >
                {" "}
                <div
                  style={{
                    width: "24px",
                    height: "24px",
                    borderRadius: "50%",
                    background: "#808B96",
                    border: "2px solid white",
                    marginRight: "8px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <GpsFixedOutlined />
                </div>
                <div style={{ marginRight: "8px" }}>
                  <Typography
                    variant="h6"
                    style={{ color: "black", fontSize: "14px" }}
                  >
                    {i18n.language === "ar"
                      ? action.localionArabic
                      : action.localite}
                  </Typography>
                </div>
                {avc.length > 0 ? null : (
                  <div
                    style={{
                      borderLeft: "1.5px solid #000043",
                      color: "black",
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      style={{
                        color: "#000043",
                        fontSize: "18px",
                        fontFamily: "Digital-7",
                        marginLeft: "3px",
                      }}
                    >
                      {`${action.pourcentage}%`}
                    </Typography>
                  </div>
                )}
              </div>
            </Link>
          ))}
      </div>
    </div>
  );
};

export default Actions;
