import React, { useRef, useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup, Polygon } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import { get, setAuthorizationHeader } from "../config/api";
import {
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import Legend from "../components/Legend";
import { Box } from "@mui/system";
import { hc } from "../data/hodhChargui";
import { useNavigate } from "react-router-dom";

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  
});

L.Marker.prototype.options.icon = DefaultIcon;

// function GetIcon(  forecast) {
//   return L.icon({
//     iconUrl: require("../assets/well/" + forecast + ".png")
//      });
// }
const HodhChaguiMap = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const mapRef = useRef(null);
  const [mapSize, setMapSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const [actions, setAction] = useState();
  const [communeFilter, setCommuneFilter] = useState("");
  const [communes, setCommunes] = useState([]);
  const [localiteFilter, setLocaliteFilter] = useState("");
  const [localities, setLocalites] = useState([]);
  const [sectorFilter, setSectorFilter] = useState("");
  const [sectors, setSectors] = useState([]);
  const [communeCode, setcommuneCode] = useState("");
  const [loadingActions, setLoadingActions] = useState(true);
  const [coordinates, setCoordinates] = useState([]);
  const drawer = localStorage.getItem("drawer");

  useEffect(() => {
   console.log(drawer)
  }, [drawer]);
  useEffect(() => {
    setCoordinates(hc.map((row) => [row[1], row[0]]));
  }, []);

  const filterSectionStyle = {
    position: "absolute",
    top: 0,
    left: "32%",
    zIndex: 1000,
    display: "flex",
    flexDirection: "column",
    width: "50%",
  };

  const rowStyle = {
    display: "flex",
    flexDirection: "row",
  };

  const formControlStyle = {
    flex: 1,
    margin: "0 10px",
  };

  const labelStyle = {
    marginLeft: `${i18n.language === "fr" ? "20px" : "0px"}`,
  };

  const fetchCommunes = async () => {
    const accessToken = localStorage.getItem("accessToken");
    setAuthorizationHeader(accessToken);
    try {
      const com = `api/v1/action/distinctCommunes`;
      const communesResponse = await get(com);
      setCommunes(communesResponse);

      const loca = `api/v1/action/by-commune/${communeCode}/localites`;
      const moughataaResponse = await get(loca);
      setLocalites(moughataaResponse);
    } catch (error) {
      console.error("Failed to fetch  :", error.message);
    }
  };

  const fetchSectors = async () => {
    const accessToken = localStorage.getItem("accessToken");
    setAuthorizationHeader(accessToken);
    try {
      const sectors = await get("/api/v1/sector/all");
      console.log(sectors);
      setSectors(sectors);
    } catch (error) {
      console.error(error);
      return;
    }
  };

  const fetchLocalities = async () => {
    const accessToken = localStorage.getItem("accessToken");
    setAuthorizationHeader(accessToken);
    try {
      const loca = `api/v1/action/by-commune/${communeCode}/localites`;
      const locResponse = await get(loca);
      setLocalites(locResponse);
    } catch (error) {
      console.error("Failed to fetch  :", error.message);
    } finally {
      setTimeout(() => {
        setLoadingActions(false);
      }, 2000);
    }
  };

  const fetchActions = async () => {
    const accessToken = localStorage.getItem("accessToken");
    setAuthorizationHeader(accessToken);
    try {
      const endpoint = `api/v1/action/filter?commune=${communeFilter}&localite=${localiteFilter}&sector=${sectorFilter}`;
      const response = await get(endpoint);
      setAction(response);
    } catch (error) {
      console.error("Failed to fetch  :", error.message);
    }
  };

  useEffect(() => {
    fetchActions();
    const handleResize = () => {
      setMapSize([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [communeFilter, localiteFilter, communeCode, sectorFilter]);

  useEffect(() => {
    if (localStorage.getItem("accessToken") === "") {
      navigate("/");
    }
    fetchCommunes();
    fetchSectors();
  }, []);

  useEffect(() => {
    fetchLocalities();
  }, [communeCode]);

  const hodhChaguiCoords = [18.6737026, -7.092877];

  return (
    <div style={{ display: "flex", position: "relative" }}>
      <div style={{ flex: 1 }}>
        <div style={filterSectionStyle}>
          <div style={rowStyle}>
            <FormControl style={formControlStyle} fullWidth>
              <InputLabel id="sector-label" style={labelStyle}>
                {t("sector")}
              </InputLabel>
              <Select
                labelId="sector-label"
                id="label-sector"
                label={t("sector")}
                value={sectorFilter}
                onChange={(e) => {
                  setSectorFilter(e.target.value);
                }}
                style={{
                  backgroundColor: "white",
                  color: "black",
                  marginLeft: `${i18n.language === "fr" ? "20px" : "0px"}`,
                  marginRight: `${i18n.language === "ar" ? "20px" : "0px"}`,
                  height: '36px',
                }}
                MenuProps={{
                  style: {
                    maxHeight: '500px',  
                    border:'1px solid blue'
                  },
                }}
              >
                <MenuItem value="">{t("all")}</MenuItem>
                {sectors?.map((sector) => (
                  <MenuItem key={sector.label} value={sector.label}>
                    {sector.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl style={formControlStyle} fullWidth>
              <InputLabel id="commune-label" style={labelStyle}>
                {t("commune")}
              </InputLabel>
              <Select
                labelId="commune-label"
                id="label-commune"
                label={t("commune")}
                value={communeFilter}
                onChange={(e) => {
                  setCommuneFilter(e.target.value);
                  setcommuneCode(e.target.value);
                }}
                style={{
                  backgroundColor: "white",
                  color: "black",
                  marginLeft: `${i18n.language === "fr" ? "20px" : "0px"}`,
                  marginRight: `${i18n.language === "ar" ? "20px" : "0px"}`,
                  height: '36px',
                }}
              >
                <MenuItem value="">{t("all")}</MenuItem>
                {communes.map((commune) => (
                  <MenuItem
                    key={commune.communeCode}
                    value={commune.communeCode}
                  >
                    {commune.communeName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl style={formControlStyle} fullWidth>
              <InputLabel id="moughtaa-label" style={labelStyle}>
                {t("localite")}
              </InputLabel>
              <Select
                labelId="moughtaa-label"
                id="label-moughtaa"
                value={localiteFilter}
                onChange={(e) => setLocaliteFilter(e.target.value)}
                fullWidth
                label={t("moughtaa")}
                style={{ backgroundColor: "white", color: "black" , height: '36px',}}
              >
                <MenuItem value="">{t("all")}</MenuItem>
                {localities.map((m) => (
                  <MenuItem key={m.id} value={m.localityCode}>
                    {m.localityName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "10px",
            }}
          >
            <FormHelperText
              style={{
                fontSize: "18px",
                color: "red",
                textAlign: "center",
                background: `${
                  actions?.length === 0 ? "white" : "transparent"
                }`,
                width: "60%",
                padding: "10px",
                border: `${actions?.length === 0 ? "1px solid red" : "none"}`,
              }}
            >
              {actions?.length === 0 ? t("noResult") : ""}
            </FormHelperText>
          </div>
        </div>

        {coordinates?.length && (
          <MapContainer
            // center={hodhChaguiCoords}
            style={
              drawer === "true"
                ? { height: mapSize[1] - 130, width: mapSize[0] - 150 }
                : { height: mapSize[1] - 120, width: mapSize[0] - 120 }
            }
            whenCreated={(mapInstance) => {
              mapRef.current = mapInstance;
            }}
            bounds={coordinates}
            boundsOptions={{ padding: [1, 1] }}
            scrollWheelZoom={true}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <Polygon positions={coordinates} />
            {actions?.map((localite, index) => {
              const coords = [localite.latitude, localite.longitude];
              let markerColor = "black";

              if (localite.pourcentage > 0 && localite.pourcentage < 15) {
                markerColor = "red";
              } else if (
                localite.pourcentage >= 15 &&
                localite.pourcentage < 25
              ) {
                markerColor = "orange";
              } else if (
                localite.pourcentage >= 25 &&
                localite.pourcentage < 65
              ) {
                markerColor = "blue";
              } else if (
                localite.pourcentage >= 65 &&
                localite.pourcentage < 90
              ) {
                markerColor = "yellow";
              } else if (localite.pourcentage >= 90) {
                markerColor = "gold";
              } else if (localite.pourcentage === 100) {
                markerColor = "green";
              }

              return (
                <>
                  {loadingActions ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        color: "red",
                        zIndex: 1000,
                        position: "absolute",
                        width: "95%",
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  ) : (
                    <Marker
                      key={index}
                      position={coords}
                      
                      icon={
                        
                        new L.Icon({
                          iconSize: [15, 25],
                          iconUrl: `https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-${markerColor}.png`,
                        })
                      }
                    >
                      <Popup>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            fontSize: "15px",
                            padding: "5px",
                            fontWeight: "bold",
                          }}
                        >
                          <Divider />
                          <span>
                            {" "}
                            {i18n.language === "ar"
                              ? localite.typeArabic
                              : localite.engagement}
                          </span>
                          <Divider />
                          <span>
                            {t("LocaliteLabel")} :{" "}
                            {i18n.language === "ar"
                              ? localite.localionArabic
                              : localite.localite}{" "}
                          </span>
                          <Divider />
                          <span>
                            {t("TauxAvancementLabel")} : %{" "}
                            {localite.pourcentage}{" "}
                          </span>
                          <Divider />
                        </div>
                      </Popup>
                    </Marker>
                  )}
                </>
              );
            })}
          </MapContainer>
        )}
      </div>
      <Legend />
    </div>
  );
};

export default HodhChaguiMap;
