import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import {
  Typography,
  Grid,
  Paper,
  Divider,
  CircularProgress,
  List,
  ListItem,
  LinearProgress,
} from "@mui/material";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { setAuthorizationHeader, get, post } from "../config/api";
import GoogleChart from "../components/PieChartComponent";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import { useTranslation } from "react-i18next";
import useAuth from "../hooks/useAuth";
import { Box } from "@mui/system";
import "../styles/dashboard.css";
import { legendData } from "../data/legendData";
import DataResume from "../components/DataResume";
import { getCounts } from "../utils/count";

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
});
L.Marker.prototype.options.icon = DefaultIcon;

const Dashboard = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [sectors, setSectors] = useState([]);
  const [actions, setAction] = useState();
  const [loadingActions, setLoadingActions] = useState(true);
  const [loadingSectors, setLoadingSectors] = useState(true);

  const totalPercentage = sectors?.reduce(
    (sum, sector) => sum + sector.pourcentageTA,
    0
  );

  function roundHalfUp(num) {
    return Math.round(num * 10) / 10;
  }
  const averagePercentage = totalPercentage / 6;
  const counts = getCounts(sectors);

  const percentages = sectors.flatMap((sector) =>
    sector.activities.map((activity) => {
      const startDate = new Date(activity.startDate);
      const endDate = new Date(activity.endDate);
      const now = new Date();

      const totalDuration = endDate - startDate;
      const elapsedDuration = now - startDate;

      if (now >= endDate) {
        return 100;
      } else if (now <= startDate) {
        return 0;
      } else {
        return (elapsedDuration / totalDuration) * 100;
      }
    })
  );

  const total = percentages.reduce((acc, curr) => acc + curr, 0);
  // const average = percentages.length ? total / percentages.length : 0;

  const averageBySectorActivities = sectors.map((sector) =>
    calculateSectorActivitiesAverage(sector.activities)
  );
  const average =
    averageBySectorActivities.reduce((acc, curr) => acc + curr, 0) / 6;

  const fetchActions = async () => {
    setAuthorizationHeader(localStorage.getItem("accessToken"));
    try {
      const endpoint = `api/v1/action/all`;
      const response = await get(endpoint);
      setAction(response);
    } catch (error) {
      console.error("Failed to fetch  :", error.message);
    } finally {
      setTimeout(() => {
        setLoadingActions(false);
      }, 0);
    }
  };

  const fetchSectors = async () => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      const decodedToken = JSON.parse(atob(accessToken.split(".")[1]));
      const isTokenExpired = decodedToken.exp * 1000 < Date.now();

      if (isTokenExpired) {
        navigate("/");
      } else {
        setAuthorizationHeader(accessToken);
        try {
          const sectors = await get("/api/v1/sector/all");
          console.log(sectors);
          setSectors(sectors);
        } catch (error) {
          navigate("/");
          console.error(error);
          return;
        } finally {
          setLoadingSectors(false);
        }
      }
    }
  };

  useEffect(() => {
    if (localStorage.getItem("accessToken") === "") {
      navigate("/");
    }
    fetchActions();
    fetchSectors();
  }, []);

  const hodhCharguiCoordinates = [18.6737026, -7.09287];

  const handleCardClick = (card) => {
    navigate(`/activities/${card.label}/${card.id}`);
  };

  function calculateSectorActivitiesAverage(sectorActivities) {
    if (!sectorActivities || sectorActivities.length === 0) {
      console.error("No activities provided or activities array is empty.");
      return 0; // Return 0% if no activities are provided
    }

    const percentages = sectorActivities.map((activity) => {
      const startDate = new Date(activity.startDate);
      const endDate = new Date(activity.endDate);
      const now = new Date();

      const totalDuration = endDate - startDate;
      const elapsedDuration = now - startDate;

      if (now >= endDate) {
        return 100;
      } else if (now <= startDate) {
        return 0;
      } else {
        return (elapsedDuration / totalDuration) * 100;
      }
    });

    const total = percentages.reduce((acc, curr) => acc + curr, 0);
    const average = percentages.length ? total / percentages.length : 0;
    return average;
  }

  return (
    <div className="dashboard-container">
      <Header sectors={sectors} header={true} />

      <DataResume
        percentage={roundHalfUp(averagePercentage).toFixed(1)}
        sectors={
          counts.sectorCount > 0 ? counts.sectorCount - 1 : counts.sectorCount
        }
        activities={counts.activityCount}
        actions={counts.actionCount}
        timePercentage={roundHalfUp(average).toFixed(1)}
      />

      <div className="card-container">
        <div style={{ display: "flex", width: "80%", flexDirection: "column" }}>
          <h1 className="title">{t("progress_rate_by_sector")}</h1>
          <Divider style={{ color: "black" }} />
          <div className="card-list">
            {loadingActions ? (
              <Box
                sx={{
                  width: "100%",
                }}
              >
                <LinearProgress />
              </Box>
            ) : (
              sectors?.map((card, index) => {
                const taux = calculateSectorActivitiesAverage(card.activities);

                const percentage = (
                  Math.round(card.pourcentageTA.toFixed(2) * 10) / 10
                ).toFixed(1);

                const matchingLegendItem = legendData.find((legendItem) => {
                  const [min, max] = legendItem.range
                    .split("-")
                    .map(parseFloat);
                  return (
                    (min <= percentage && percentage <= max) ||
                    percentage === legendItem.range
                  );
                });

                const backgroundColor = matchingLegendItem
                  ? matchingLegendItem.color
                  : "black";

                return (
                  // <button
                  //   className="card"
                  //   key={index}
                  //   style={{
                  //     backgroundColor: "white",
                  //     color: "black",
                  //     border: "2px solid #000043",
                  //     position: "relative",
                  //   }}
                  //   onClick={() => handleCardClick(card)}
                  // >
                  //   <p className="label">
                  //     {i18n.language === "ar" ? card.labelArabic : card.label}
                  //   </p>
                  //   <div>
                  //     <img
                  //       src={`${
                  //         card.label === "Education"
                  //           ? "/assets/education.png"
                  //           : card.label === "Agriculture"
                  //           ? "/assets/field.png"
                  //           : card.label === "Elevage"
                  //           ? "/assets/person.png"
                  //           : card.label === "Sécurité Alimentaire"
                  //           ? "/assets/food-safety.png"
                  //           : card.label === "Hydraulique"
                  //           ? "/assets/hydraulic-breaker.png"
                  //           : card.label === "Réserve"
                  //           ? "/assets/budget.png"
                  //           : "/assets/medicine.png"
                  //       }`}
                  //       alt="Icon"
                  //     />
                  //   </div>
                  //   <p
                  //     className="percentage"
                  //     style={{
                  //       backgroundColor: backgroundColor,
                  //       color:
                  //         backgroundColor === "#ABEBC6" ||
                  //         backgroundColor === "yellow"
                  //           ? "#000043"
                  //           : "white",
                  //     }}
                  //   >
                  //     {percentage}%
                  //   </p>

                  //   <div
                  //     style={{
                  //       width: "100%",
                  //       background: "#000043",
                  //       color: "white",
                  //       position: "absolute",
                  //       bottom: "0px",
                  //       width: "100%",
                  //       padding: "2px",
                  //       textAlign: "center",
                  //       fontSize: "10px",
                  //     }}
                  //   >
                  //     Temps total cumulé : {taux.toFixed(1)}%
                  //   </div>
                  //   <div
                  //     style={{
                  //       position: "absolute",
                  //       top: "0px",
                  //       right: "0px",
                  //       fontSize: "12px",
                  //       background: percentage < 50 ? "red" : "blue",
                  //       height: "20px",
                  //       alignItems: "center",
                  //       display: "flex",
                  //       justifyContent: "center",
                  //       padding: "3px",
                  //       borderBottomLeftRadius: "10px",
                  //       borderTopRightRadius: "8px",
                  //       borderBottom: "1px solid #333",
                  //       borderLeft: "1px solid #333",
                  //     }}
                  //   >
                  //     <p style={{ color: "white" }}>
                  //       {percentage < 50 ? "Critique" : "Avancée"}
                  //     </p>{" "}
                  //   </div>
                  // </button>

                  <div
                    key={index}
                    className={taux < 100 ? "cardNew" : "cardNew100"}
                   
                    onClick={() => handleCardClick(card)}
                  >
                    <div
                      className="status"
                      style={{
                        backgroundColor: percentage < 50 ? "red" : "blue",
                      }}
                    >
                      {" "}
                      {percentage < 50 ? "Critique" : "Avancée"}
                    </div>
                    <div className="content">
                      <h3 className="title">
                        {" "}
                        {i18n.language === "ar" ? card.labelArabic : card.label}
                      </h3>
                      <img
                        src={`${
                          card.label === "Education"
                            ? "/assets/education.png"
                            : card.label === "Agriculture"
                            ? "/assets/field.png"
                            : card.label === "Elevage"
                            ? "/assets/person.png"
                            : card.label === "Sécurité Alimentaire"
                            ? "/assets/food-safety.png"
                            : card.label === "Hydraulique"
                            ? "/assets/hydraulic-breaker.png"
                            : card.label === "Réserve"
                            ? "/assets/budget.png"
                            : "/assets/medicine.png"
                        }`}
                        alt="Icon"
                      />
                      <div
                        className="percentageNew"
                        style={{
                          backgroundColor: backgroundColor,
                          color:
                            backgroundColor === "#ABEBC6" ||
                            backgroundColor === "yellow"
                              ? "#000043"
                              : "white",
                        }}
                      >
                        {percentage}%
                      </div>
                    </div>
                    <div className="footer">
                      {" "}
                      Temps total cumulé{" "}
                      <div
                        style={{
                          borderLeft: "1px solid white",
                          marginLeft: "5px",
                          paddingLeft: "5px",
                        }}
                      >
                        {taux.toFixed(1)}%{" "}
                      </div>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
        <List className="indicator-list">
          <ListItem>{t("Legend Title")}</ListItem>
          <Divider />
          {legendData.map((legend, index) => (
            <React.Fragment key={index}>
              <ListItem className="indicator" sx={{ marginBottom: "-30px" }}>
                <div
                  className="indicator-color"
                  style={{ backgroundColor: legend.color }}
                ></div>
                <p className="indicator-label">{legend.range}</p>
              </ListItem>
              {index < legendData.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </List>
      </div>

      <div className="dashboard-content">
        <MapContainer
          center={hodhCharguiCoordinates}
          zoom={6.3}
          className="map-container"
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          {actions?.map((localite, index) => {
            const coords = [localite.latitude, localite.longitude];
            let markerColor = "black";

            if (localite.pourcentage > 0 && localite.pourcentage < 15) {
              markerColor = "red";
            } else if (
              localite.pourcentage >= 15 &&
              localite.pourcentage < 25
            ) {
              markerColor = "orange";
            } else if (
              localite.pourcentage >= 25 &&
              localite.pourcentage < 65
            ) {
              markerColor = "blue";
            } else if (
              localite.pourcentage >= 65 &&
              localite.pourcentage < 90
            ) {
              markerColor = "yellow";
            } else if (localite.pourcentage >= 90) {
              markerColor = "green";
            } else if (localite.pourcentage === 100) {
              markerColor = "darkblue";
            }

            return (
              <Marker
                key={index}
                position={coords}
                icon={
                  new L.Icon({
                    iconSize: [15, 25],
                    iconUrl: `https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-${markerColor}.png`,
                  })
                }
              >
                <Popup>
                  <div className="popup-content">
                    <Divider />
                    <span>
                      {" "}
                      {i18n.language === "ar"
                        ? localite.typeArabic
                        : localite.engagement}
                    </span>
                    <Divider />
                    <span>
                      {t("LocaliteLabel")} :{" "}
                      {i18n.language === "ar"
                        ? localite.localionArabic
                        : localite.localite}{" "}
                    </span>
                    <Divider />
                    <span>
                      {t("TauxAvancementLabel")} : % {localite.pourcentage}{" "}
                    </span>
                    <Divider />
                  </div>
                </Popup>
              </Marker>
            );
          })}
        </MapContainer>

        <div className="chart-container">
          {loadingActions && (
            <Box className="loading-spinner">
              <CircularProgress />
            </Box>
          )}
          {!loadingActions && <GoogleChart data={sectors} />}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
