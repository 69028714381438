import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import { ExitToApp, MapOutlined } from "@mui/icons-material";
import People from "@mui/icons-material/People";
import LanguageSwitcher from "./LanguageSwitcher";

const drawerWidth = 190;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isArabic = i18n.language === "ar";
  const role = localStorage.getItem("role");
  const [selectedItem, setSelectedItem] = React.useState("dashboard");

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    navigate("/");
    localStorage.setItem("accessToken", "");
  };

  React.useEffect(() => {
    localStorage.setItem('drawer', open);
  }, [open]);

  const handleItemClick = (item) => {
    setSelectedItem(item);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}  >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h5" noWrap component="div">
           {t('anetat')}
          </Typography>
          <div style={{ flexGrow: 1 }} />
          <LanguageSwitcher />
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        dir={isArabic ? "rtl" : "ltr"}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItem
            component={Link}
            to="/dashboard"
            disablePadding
            sx={{ display: "block" }}
            onMouseEnter={() => !open && setSelectedItem("dashboard")}
            onMouseLeave={() => !open && setSelectedItem(null)}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
                backgroundColor: selectedItem === "dashboard" ? "#2196f3" : "transparent",
                color: selectedItem === "dashboard" ? "black" : "inherit",
              }}
              onClick={() => handleItemClick("dashboard")}
            >
              <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : "auto", justifyContent: "center" }}>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary={t("dashboard")} sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>

          <ListItem
            component={Link}
            to="/map"
            disablePadding
            sx={{ display: "block" }}
            onMouseEnter={() => !open && setSelectedItem("map")}
            onMouseLeave={() => !open && setSelectedItem(null)}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
                backgroundColor: selectedItem === "map" ? "#2196f3" : "transparent",
                color: selectedItem === "map" ? "black" : "inherit",
              }}
              onClick={() => handleItemClick("map")}
            >
              <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : "auto", justifyContent: "center" }}>
                <MapOutlined />
              </ListItemIcon>
              <ListItemText primary={t("map")} sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>

          {role === "MANAGER" && (
            <ListItem
              component={Link}
              to="/users"
              disablePadding
              sx={{ display: "block" }}
              onMouseEnter={() => !open && setSelectedItem("users")}
              onMouseLeave={() => !open && setSelectedItem(null)}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                  backgroundColor: selectedItem === "users" ? "#2196f3" : "transparent",
                  color: selectedItem === "users" ? "black" : "inherit",
                }}
                onClick={() => handleItemClick("users")}
              >
                <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : "auto", justifyContent: "center" }}>
                  <People />
                </ListItemIcon>
                <ListItemText primary={t("users")} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          )}
        </List>
        <Divider />
        <List sx={{ position: "absolute", bottom: 0, width: "100%" }}>
          <ListItem
            button
            component="div"
            sx={{
              textAlign: isArabic ? "right" : "left",
              color: "#90a4ae",
              backgroundColor: "transparent",
            }}
            onClick={handleLogout}
          >
            <ListItemIcon>
              <ExitToApp sx={{ color: "black" }} />
            </ListItemIcon>
            <ListItemText sx={{ color: "black", order: isArabic ? 2 : 1 }} primary={t("logout")} />
          </ListItem>
        </List>
      </Drawer>
    </Box>
  );
}
