export const hc = [
  [-7.987723963716273, 19.72541121753693],
  [-7.974236417467056, 19.753781723429217],
  [-6.37903662880888, 23.107607975571426],
  [-6.372086147394419, 23.122981720419812],
  [-6.371634198839031, 23.123981240840077],
  [-6.360563109313866, 23.029654790325967],
  [-6.346455445658159, 22.90896467731754],
  [-6.332296102266393, 22.788300092442302],
  [-6.318188434575024, 22.6676613169029],
  [-6.3041324468840605, 22.54699695906498],
  [-6.289973104465071, 22.42628086323815],
  [-6.275865438022778, 22.30561635599397],
  [-6.261706096132232, 22.184951895643096],
  [-6.247598430593167, 22.06423579062225],
  [-6.233490763415801, 21.943571216785433],
  [-6.219383096861285, 21.822855110517462],
  [-6.205223754887093, 21.702242319769898],
  [-6.1911160884230885, 21.581577808297016],
  [-6.177008422917191, 21.460861707385927],
  [-6.1629007564207425, 21.340197192373356],
  [-6.148741414227628, 21.219532797218637],
  [-6.134633747685884, 21.09886827756492],
  [-6.120526083028421, 20.97820383968558],
  [-6.113704793300236, 20.919551115379534],
  [-6.0986152751979334, 20.7907217598241],
  [-6.0835774343823825, 20.661892431544835],
  [-6.0767561440141815, 20.603342998363456],
  [-6.064095418105359, 20.495442617139513],
  [-6.0496776941521055, 20.370489007700765],
  [-6.035363321995896, 20.245483703613658],
  [-6.020945598033081, 20.120530094231036],
  [-6.006579548518097, 19.995524753950058],
  [-5.992161825335398, 19.870597155866434],
  [-5.977847454403618, 19.745617712053512],
  [-5.963533080791537, 19.62061226443342],
  [-5.949115355586254, 19.495632791995735],
  [-5.949115355656968, 19.495529446004756],
  [-5.934025839095045, 19.357940989468158],
  [-5.927566282928938, 19.299546504143354],
  [-5.9212100833981705, 19.241229796811602],
  [-5.914802204562944, 19.182886921026274],
  [-5.908394327405815, 19.124570151349594],
  [-5.898214071393672, 19.031785216674457],
  [-5.888033814611672, 18.939051880871787],
  [-5.877801880109034, 18.846318473080558],
  [-5.867673298936358, 18.7535849956164],
  [-5.857544718076572, 18.66080007784753],
  [-5.847364461548632, 18.568066768375896],
  [-5.837080851408719, 18.47533339617997],
  [-5.826952270711324, 18.382548290098494],
  [-5.816772015278119, 18.289892610059674],
  [-5.806540079515036, 18.197081553291746],
  [-5.796359823052637, 18.104374090538418],
  [-5.786179566110379, 18.01166657666877],
  [-5.776361044265546, 17.921439648294935],
  [-5.766387491147014, 17.83126404904668],
  [-5.756465616492819, 17.741037034643487],
  [-5.746647094486909, 17.650758306720025],
  [-5.736673543081364, 17.560582888485953],
  [-5.726803344763219, 17.470355911211982],
  [-5.716933146111778, 17.380180574260624],
  [-5.70695959518399, 17.2899535322793],
  [-5.697037720001063, 17.199726461133913],
  [-5.687167521578018, 17.109499362957543],
  [-5.677297323248661, 17.019324063911196],
  [-5.6673237704072426, 16.92909691808448],
  [-5.657453571744718, 16.838818228002342],
  [-5.647583373860883, 16.74864286709428],
  [-5.637609822879437, 16.658364142126597],
  [-5.627687947747882, 16.568137076773322],
  [-5.6233471271756095, 16.527829509434486],
  [-5.614562134535941, 16.5118615335105],
  [-5.60901722382324, 16.501662854511977],
  [-5.608452525212344, 16.49529374981665],
  [-5.571486419510813, 16.472828685387643],
  [-5.56650305251809, 16.46948685810052],
  [-5.52970943262733, 16.444992241581804],
  [-5.485371054303096, 16.415433284388843],
  [-5.445941934932687, 16.38902658285769],
  [-5.4049625249417135, 16.36168975088606],
  [-5.364913288707242, 16.334921330900297],
  [-5.360107382506796, 16.32990881459409],
  [-5.355301473115556, 16.324792800298034],
  [-5.354267944250816, 16.31838493903728],
  [-5.353286092377517, 16.311977077745272],
  [-5.358918823065797, 16.282366443163717],
  [-5.377212280170519, 16.18779858519562],
  [-5.395454061264318, 16.09317904993054],
  [-5.413799194756555, 15.998559438600259],
  [-5.431989298855351, 15.903939903879525],
  [-5.450799519775174, 15.80683987674658],
  [-5.469558064725139, 15.709791530318846],
  [-5.488368286079771, 15.612665720120848],
  [-5.507075154921086, 15.515539846969684],
  [-5.50805700706122, 15.510656441801245],
  [-5.508761371395204, 15.506899834764265],
  [-5.508987182939574, 15.505695488326605],
  [-5.5092553643457265, 15.504287525852435],
  [-5.517409649878548, 15.499859083569703],
  [-5.5096328766351865, 15.5023055929778],
  [-5.509917359080336, 15.500812083234678],
  [-5.510950886934681, 15.495902803697856],
  [-5.510950887026054, 15.495670271330683],
  [-5.510950887117426, 15.495437738963574],
  [-5.510950886880754, 15.495282679831883],
  [-5.510950886963721, 15.495075984394582],
  [-5.510950887063498, 15.494817615098071],
  [-5.5109508871296455, 15.49466259352022],
  [-5.5109508868851345, 15.494507534388706],
  [-5.510950886975951, 15.494300838951684],
  [-5.510950887017433, 15.494197491233193],
  [-5.511105917067933, 15.494042469655493],
  [-5.51126094609253, 15.493990795796249],
  [-5.511312622134039, 15.493887448077833],
  [-5.511364298905253, 15.493732388946523],
  [-5.511519327921991, 15.493680715087315],
  [-5.511622679980427, 15.493525693509742],
  [-5.51172603402183, 15.49342234579139],
  [-5.511932740021804, 15.49342234579139],
  [-5.51219112102187, 15.49342234579139],
  [-5.5122944740218, 15.49342234579139],
  [-5.512552856021855, 15.49342234579139],
  [-5.512759562021829, 15.49342234579139],
  [-5.512966268021884, 15.49342234579139],
  [-5.513121297021813, 15.49342234579139],
  [-5.513379680021887, 15.49342234579139],
  [-5.513741414980287, 15.493525693509742],
  [-5.514051472938868, 15.493629041228132],
  [-5.514361531905203, 15.493732388946523],
  [-5.514671589855825, 15.493835736664938],
  [-5.514981649125716, 15.493913285007423],
  [-5.515291708067925, 15.494042469655493],
  [-5.515601766034242, 15.49414581737396],
  [-5.515963500992741, 15.49424916509244],
  [-5.673163207975329, 15.494300838951684],
  [-5.807315226948694, 15.494352512810943],
  [-5.8702571209481444, 15.494352512810943],
  [-5.93821162994752, 15.494352512810943],
  [-6.010817015937733, 15.494378349740574],
  [-6.087814900900425, 15.494455860529461],
  [-6.221605183870327, 15.494507534388706],
  [-6.308921828354465, 15.494539079477226],
  [-6.320144865110446, 15.494543135274323],
  [-6.364748900847942, 15.49455920824799],
  [-6.516005819173166, 15.494610919660936],
  [-6.67392899615702, 15.49466259352022],
  [-6.837174845110134, 15.494740104309146],
  [-7.004503133080742, 15.494817615098071],
  [-7.174518596082701, 15.494817615098071],
  [-7.345877645029349, 15.494920962816666],
  [-7.517236694007174, 15.494972636675975],
  [-7.6872004809729, 15.495024310535285],
  [-7.854528767948325, 15.495075984394582],
  [-8.017826293922854, 15.495127658253915],
  [-8.17580114687228, 15.495205169042894],
  [-8.32700638881983, 15.495282679831883],
  [-8.470201781816833, 15.495282679831883],
  [-8.603940389209635, 15.495386065104226],
  [-8.6893098561852, 15.495437738963574],
  [-8.769253297186916, 15.495437738963574],
  [-8.84340897718846, 15.495437738963574],
  [-8.911156779190003, 15.495437738963574],
  [-9.003215639268959, 15.49552629053359],
  [-9.003036667604883, 15.4956694451498],
  [-9.000840420074772, 15.497426469031746],
  [-9.000799725598878, 15.497474387523555],
  [-8.995595261740394, 15.503601795703732],
  [-8.959370083195111, 15.539077666623882],
  [-8.969524502342168, 15.856965266997161],
  [-9.025515916018868, 15.974296597936801],
  [-9.067477178475919, 16.062172316747912],
  [-8.869039678446102, 16.321639642260713],
  [-8.893766852936514, 16.391713023476782],
  [-8.750442267681233, 16.531678611602583],
  [-8.686544359836065, 16.601157575880865],
  [-8.51807918801817, 16.78078474994062],
  [-8.289230109568896, 17.0230439775963],
  [-8.460201584420476, 17.204221479525255],
  [-8.598332688383149, 17.350620783330438],
  [-9.149746060098442, 17.282330449566402],
  [-9.146981370669323, 17.288040701541618],
  [-7.987723963716273, 19.72541121753693],
];
