import React, { useState, useEffect } from 'react';
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Modal, TextField, Typography, TablePagination, makeStyles, Dialog, DialogContent, AppBar, IconButton, MenuItem, Select, DialogActions, tableCellClasses, Alert } from '@mui/material';
import Header from '../components/Header';
import { setAuthorizationHeader, get, post } from '../config/api';
import { CloseOutlined } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: 'black',
        color: 'white',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: 'aqua',
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const Users = () => {
    const { enqueueSnackbar } = useSnackbar();
    const {t,i18n} = useTranslation();
    const navigate = useNavigate();

    const [users, setUsers] = useState([]);
    const [showAddUserModal, setShowAddUserModal] = useState(false);
    const [newUserDetails, setNewUserDetails] = useState({
        firstname: '',
        lastname: '',
        email: '',
        password: '',
        role: 'ADMIN',
    });

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [formError, setFormError] = useState('');

    const validateForm = () => {
        if (!newUserDetails.firstname || !newUserDetails.lastname || !newUserDetails.email || !newUserDetails.password || !newUserDetails.role) {
            setFormError(`${t('errorRegister')}`);
            return false;
        }
        setFormError('');
        return true;
    };

    useEffect(() => {
        if(localStorage.getItem("accessToken")===""){
            navigate('/');
         }
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        const accessToken = localStorage.getItem('accessToken');
        setAuthorizationHeader(accessToken);
        try {
            const fetchedUsers = await get('api/v1/users');
            setUsers(fetchedUsers);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleAddUser = async () => {
        if (validateForm()) {


            const accessToken = localStorage.getItem('accessToken');
            setAuthorizationHeader(accessToken);
            try {
                const response = await post('api/v1/auth/register', newUserDetails);

                if (response.status === 200) {
                    enqueueSnackbar('Utilisateur ajouter avec success', 'success');
                }
                fetchUsers();
                setShowAddUserModal(false);
            } catch (error) {
                console.error('Error adding user:', error);
            }
        }
    };

    return (
        <div>
            <Grid container justifyContent="space-between" alignItems="center" style={{ borderBottom: '1px solid #ccc', padding: '15px 0' }}>
                <Grid item>
                    <Typography variant="h6">
                        {t('usersList')}
                    </Typography>
                </Grid>
                <Grid item>
                    <Button
                        style={{ textTransform: 'none', padding: '8px 16px', border: '1px solid #ccc', borderRadius: '4px' }}
                        variant="contained"
                        onClick={() => setShowAddUserModal(true)}
                    >
                        {t('add')}
                    </Button>
                </Grid>
            </Grid>

            <TableContainer component={Paper} style={{ marginTop: '20px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>{t('idLabel')}</StyledTableCell>
                            <StyledTableCell>{t('nomLabel')}</StyledTableCell>
                            <StyledTableCell>{t('prenomLabel')}</StyledTableCell>
                            <StyledTableCell>{t('utilisateurLabel')}</StyledTableCell>
                            <StyledTableCell>{t('roleLabel')}</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(rowsPerPage > 0
                            ? users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : users
                        ).map((user) => (
                            <StyledTableRow key={user.id}>
                                <StyledTableCell >{user.id}</StyledTableCell >
                                <StyledTableCell >{user.lastname}</StyledTableCell >
                                <StyledTableCell >{user.firstname}</StyledTableCell >
                                <StyledTableCell >{user.username}</StyledTableCell >
                                <StyledTableCell >{user.role}</StyledTableCell >
                            </StyledTableRow >
                        ))}
                    </TableBody>
                </Table>
                <TablePagination
                    // rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                    labelRowsPerPage={i18n.t('rowsPerPage')}
                    rowsPerPageOptions={[5, 10, 25, { label: i18n.t('all'), value: -1 }]}
                    component="div"
                    count={users.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer>

            <Dialog open={showAddUserModal} onClose={() => setShowAddUserModal(false)}>
                <AppBar position="relative" sx={{ backgroundColor: '#2196F3' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '10px' }}>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1, textAlign: 'center', color: '#fff' }}>
                            {t('addUserLabel')}
                        </Typography>
                        <IconButton color="inherit" onClick={() => setShowAddUserModal(false)} aria-label="close">
                            <CloseOutlined />
                        </IconButton>
                    </div>
                </AppBar>

                <DialogContent
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        width: '300px',
                        padding: '20px',
                        background: '#fff',
                        borderRadius: '8px',
                        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                        margin: '20px',
                    }}
                >
                    {formError && <Alert severity="error" sx={{ marginBottom: '15px' }}>{formError}</Alert>}

                    <TextField
                        label={t('prenomLabel')}
                        type="text"
                        value={newUserDetails.firstname}
                        onChange={(e) => setNewUserDetails({ ...newUserDetails, firstname: e.target.value })}
                        style={{ marginBottom: '15px' }}
                        fullWidth
                        required
                    />

                    <TextField
                        label={t('nomLabel')}
                        type="text"
                        value={newUserDetails.lastname}
                        onChange={(e) => setNewUserDetails({ ...newUserDetails, lastname: e.target.value })}
                        style={{ marginBottom: '15px' }}
                        fullWidth
                        required
                    />

                    <TextField
                        label={t('utilisateurLabel')}
                        type="text"
                        value={newUserDetails.email}
                        onChange={(e) => setNewUserDetails({ ...newUserDetails, email: e.target.value })}
                        style={{ marginBottom: '15px' }}
                        fullWidth
                        required
                    />

                    <TextField
                        label={t('password')}
                        type="password"
                        value={newUserDetails.password}
                        onChange={(e) => setNewUserDetails({ ...newUserDetails, password: e.target.value })}
                        style={{ marginBottom: '15px' }}
                        fullWidth
                        required
                    />

                    <Select
                        value={newUserDetails.role}
                        onChange={(e) => setNewUserDetails({ ...newUserDetails, role: e.target.value })}
                        style={{ marginBottom: '15px' }}
                        fullWidth
                        required
                    >
                        <MenuItem value="ADMIN">{t('agentRole')}</MenuItem>
                        <MenuItem value="MANAGER">{t('managerRole')}</MenuItem>
                    </Select>

                    <DialogActions>
                        <Button style={{textTransform:'none'}} onClick={handleAddUser} variant="contained" color="primary" autoFocus>
                            {t('add')}
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>


        </div>
    );
};

export default Users;
