export const getCounts = (sectors) => {
    let sectorCount = 0;
    let activityCount = 0;
    let actionCount = 0;
  
    sectors?.forEach((sector) => {
      sectorCount++;
      sector.activities?.forEach((activity) => {
        activityCount++;
        actionCount += activity.actionList?.length;
      });
    });
  
    return {
      sectorCount,
      activityCount,
      actionCount,
    };
  };