import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Outlet,
} from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Activities from "./pages/Activities";
import Actions from "./pages/Actions";
import Action from "./pages/Action";
import LeftBar from "./components/LeftBar";
import HodhChaguiMap from "./pages/Map";
import Login from "./pages/Login";
import Users from "./pages/Users";
import { useTranslation } from "react-i18next";
import NotFound from "./pages/NotFound";
import Navbar from "./components/Navbar";
import MiniDrawer from "./components/SideBar";
import { Box } from "@mui/system";
import { styled, useTheme } from "@mui/material/styles";
import Footer from "./components/Footer";

const App = () => {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "ar";

  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const mainContainerStyle = {
    marginLeft: isArabic ? "15%" : "20%",
    direction: isArabic ? "rtl" : "ltr",
    padding: "20px",
  };

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  }));
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <div style={{ textAlign: "center" }}>
              <Login />
            </div>
          }
        />
        <Route
          path="/*"
          element={
            <>
              <Box sx={{ display: "flex" }}>
                {/* {isArabic && <LeftBar isSmallScreen={isSmallScreen} />} */}
                <MiniDrawer />
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                  <DrawerHeader />
                  <Routes>
                    <Route exact path="/index.html" element={<Dashboard />} />
                    <Route path="/map" element={<HodhChaguiMap />} />
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route
                      path="/activities/:sectorName/:sectorId"
                      element={<Activities />}
                    />
                    <Route
                      path="/actions/:activityName/:sectorName/:activityId"
                      element={<Actions />}
                    />
                    <Route path="/action/:actionId" element={<Action />} />
                    <Route path="/users" element={<Users />} />
                    <Route path="*" element={<Dashboard />} />
                  </Routes>
                </Box>
              </Box>
              <Footer />
            </>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
