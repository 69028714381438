import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Button,
  TablePagination,
  Chip,
  Grid,
  Select,
  MenuItem,
} from "@mui/material";
import Header from "../components/Header";
import { setAuthorizationHeader, get, post } from "../config/api";
import {
  ArrowBack,
  ArrowBackIos,
  ArrowForwardIos,
  HomeOutlined,
} from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import ActionLineChart from "../components/ActionLineChart";

function formatDate(dateString) {
  const date = new Date(dateString);
  const options = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };
  return date.toLocaleDateString("en-GB", options);
}
const appBarStyle = {
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "row",
  background: "#007bff",
  color: "#fff",
  width: "80%",
  marginBottom: "20px",
  alignItems: "center",
  padding: "10px",
  borderRadius: "8px",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
};

const iconStyle = {
  fontSize: "24px",
  cursor: "pointer",
  color: "white",
};

const titleStyle = {
  flex: 1,
  textAlign: "center",
  margin: "0 15px",
  color: "#fff",
};

const Action = () => {
  const { t, i18n } = useTranslation();
  const { actionId } = useParams();
  const navigate = useNavigate();
  // const [messageApi, contextHolder] = message.useMessage();

  const { enqueueSnackbar } = useSnackbar();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [action, setAction] = useState();
  const [jalon, setJalons] = useState([]);

  // const error = () => {
  //   messageApi.open({
  //     type: 'error',
  //     content: 'This is an error message',
  //   });
  // };

  const goBack = () => {
    navigate(-1);
  };

  const [percentage, setPercentage] = useState("");
  const [comment, setComment] = useState("");
  const [at, setAt] = useState("");
  const [lo, setLo] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const parsedPercentage = parseFloat(percentage);
      const parsedActionPercentage =
        action?.jalonList.length > 0 ? parseFloat(action?.pourcentage) : 0;

      if (!isNaN(parsedPercentage) && !isNaN(parsedActionPercentage)) {
        const newPercentage = parsedPercentage + parsedActionPercentage;
        if (newPercentage > 100) {
          // Show error modal or handle the error in your preferred way
          console.error("Error: Percentage cannot exceed 100%");
          // Optionally display an error modal or set an error state
          return;
        }

        const response = await update(actionId, newPercentage, comment);
        console.log(response);

        setPercentage("");
        setComment("");
      } else {
        console.error("Invalid percentage values");
      }
    } catch (error) {
      console.error("Failed to submit form:", error.message);
    }
  };

  const handleSubmitAL = async (event) => {
    event.preventDefault();
    try {
      const response = await updateAL(actionId, at, lo);
      console.log(response);
      setAt("");
      setLo("");
    } catch (error) {
      console.error("Failed to submit form:", error.message);
    }
  };

  const update = async (actionId, percentage, comment) => {
    try {
      const endpoint = `api/v1/action/${actionId}/modifyPourcentage?percentage=${percentage}&comment=${comment}`;
      const response = await post(endpoint);

      if (response) {
        enqueueSnackbar("Modification effectuer avec success", "success");
        await fetchActions(actionId);
      } else {
        console.error("Failed to update action percentage");
      }
    } catch (error) {
      console.error("Failed to update action percentage:", error.message);
    }
  };

  const updateAL = async (actionId, atitude, longitude) => {
    try {
      const endpoint = `api/v1/action/${actionId}/coords?at=${atitude}&lo=${longitude}`;
      const response = await post(endpoint);

      if (response) {
        enqueueSnackbar("Modification effectuer avec success", "success");
        await fetchActions(actionId);
      } else {
        console.error("Failed to update action percentage");
      }
    } catch (error) {
      console.error("Failed to update action percentage:", error.message);
    }
  };

  const fetchActions = async () => {
    const accessToken = localStorage.getItem("accessToken");
    setAuthorizationHeader(accessToken);
    try {
      const endpoint = `api/v1/action/${actionId}`;
      const response = await get(endpoint);
      setAction(response);
      console.log(response);
    } catch (error) {
      console.error("Failed to fetch  :", error.message);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("accessToken") === "") {
      navigate("/");
    }
    fetchActions();
  }, []);
  useEffect(() => {
    fetchActions();
  }, [percentage]);

  const historyChanges = Array.from({ length: 15 }, (_, index) => ({
    date: `2022-02-${15 + index}`,
    actor: `Actor ${index + 1}`,
    percentage: Math.floor(Math.random() * 100),
  }));

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      {/* {contextHolder} */}
      <div style={appBarStyle}>
        <ArrowForwardIos
          style={{ ...iconStyle, transform: "rotate(180deg)" }}
          onClick={goBack}
        />
        <Typography variant="h6" style={titleStyle}>
          {i18n.language === "ar" ? action?.labelArabic : action?.label}
        </Typography>
        {/* <Link to="/dashboard" style={{ textDecoration: "none" }}>
          <HomeOutlined style={iconStyle} />
        </Link> */}
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "80%",
          marginBottom: "20px",
        }}
      >
        <Paper
          style={{
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography gutterBottom>
            {i18n.language === "ar" ? action?.localionArabic : action?.localite}
          </Typography>

          <div
            style={{
              height: "90%",
              width: "40px",
              backgroundColor: "#e0e0e0",
              borderRadius: "4px",
              margin: "10px 0",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}
          >
            <div
              style={{
                height: `${action?.pourcentage}%`,
                backgroundColor: "blue",
                borderRadius: "4px",
              }}
            ></div>
          </div>

          <Typography variant="subtitle1">
            {`${action?.pourcentage}`}%
          </Typography>
        </Paper>

        <Paper style={{ flex: "1", marginLeft: "20px" }}>
          <Typography variant="h6" gutterBottom>
            {t("historyLabel")}
          </Typography>
          {/* <TableContainer style={{ maxWidth: '100%' }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>{t('dateLabel')}</TableCell>
                  <TableCell>{t('commentLabel')}</TableCell>
                  <TableCell>{t('pourcentageLabel')}</TableCell>
                 </TableRow>
              </TableHead>
              <TableBody>
                {action?.history.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{formatDate(row.date)}</TableCell>
                    <TableCell>{row.commentaire}</TableCell>
                    <TableCell>
                      <div style={{ width: '100px', height: '20px', backgroundColor: '#e0e0e0', borderRadius: '4px', margin: '10px 0', display: 'flex' }}>
                        <div style={{ width: `${row.percentage}%`, backgroundColor: '#2196f3', borderRadius: '4px' }}></div>
                      </div>
                      {`${row.currentPourcentage}%`}
                    </TableCell>
                    
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
             labelRowsPerPage={t('rowsPerPage')}
            rowsPerPageOptions={[5, 10, 25, { label: t('all'), value: -1 }]}
            component="div"
            count={historyChanges.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
          <ActionLineChart history={action?.history} />
        </Paper>
      </div>

      <Grid container sx={{ paddingLeft: 10 }}>
        <Grid item xs={6}>
          <Paper
            style={{
              padding: "20px",
              width: "80%",
              marginTop: "20px",
              marginLeft: "30px",
            }}
          >
            <Typography variant="h6" gutterBottom>
              {t("saisirTauxLabel")}
            </Typography>
            <form onSubmit={handleSubmit}>
              {/* {action?.jalonList?.length === 0 ? ( */}
                <TextField
                  label="%"
                  variant="outlined"
                  type="number"
                  fullWidth
                  margin="normal"
                  value={percentage}
                  onChange={(e) => setPercentage(e.target.value)}
                />
               {/*// ) : (
                // <Select
                //   labelId="percentage-label"
                //   id="percentage-select"
                //   value={percentage}
                //   onChange={(e) => {
                //     setPercentage(e.target.value);
                //   }}
                //   label="%"
                //   fullWidth
                // >
                //   {action?.jalonList?.map((option, index) => (
                //     <MenuItem key={index} value={option?.percentage}>
                //       {i18n.language === "ar" ? option.labelArab : option.label}
                //     </MenuItem>
                //   ))}
                // </Select>
              // )}
              */}

              <TextField
                label={t("commentLabel")}
                multiline
                rows={1}
                variant="outlined"
                fullWidth
                margin="normal"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
              <Button
                variant="contained"
                color="primary"
                type="submit"
                style={{ marginTop: "10px", textTransform: "none" }}
              >
                {t("add")}
              </Button>
            </form>
          </Paper>
        </Grid>

        <Grid item xs={6}>
          <Paper style={{ padding: "20px", width: "80%", marginTop: "20px" }}>
            <Typography variant="h6" gutterBottom>
              {t("changeGPS")}
            </Typography>
            <form onSubmit={handleSubmitAL}>
              <TextField
                label={action?.latitude}
                variant="outlined"
                fullWidth
                margin="normal"
                value={at}
                onChange={(e) => {
                  setAt(e.target.value);
                }}
              />
              <TextField
                label={action?.longitude}
                variant="outlined"
                fullWidth
                margin="normal"
                value={lo}
                onChange={(e) => {
                  setLo(e.target.value);
                }}
              />
              <Button
                variant="contained"
                color="primary"
                type="submit"
                style={{ marginTop: "10px", textTransform: "none" }}
              >
                {t("update")}
              </Button>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default Action;
