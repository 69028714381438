import React from "react";
import { useTranslation } from "react-i18next";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Box, Typography } from "@mui/material";

const LanguageSwitcher = () => {
   const { t,i18n } = useTranslation();

  const isArabic = i18n.language === "ar";

  const changeLanguage = (event) => {
    const selectedLanguage = event.target.value;
    i18n.changeLanguage(selectedLanguage);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: isArabic ? "row-reverse" : "row", alignItems: "center", justifyContent: "space-between" }}>
      {/* <Typography
        variant="body2"
        sx={{
          color: "#90a4ae",
          marginLeft: isArabic ? "10px" : "0",
          marginRight: isArabic ? "0" : "10px",
          order: isArabic ? 2 : 1,
        }}
      >
        {t('language')}
      </Typography> */}
      <Select
        value={i18n.language}
        onChange={changeLanguage}
        sx={{
          color: "white",
          order: 2,
          minHeight: "5px",  
          fontSize: "14px",  
          borderColor:'white'
         }}
        label={t('language')}
      >
        <MenuItem value="ar">العربية</MenuItem>
        <MenuItem value="fr">Français</MenuItem>
      </Select>
    </Box>
  );
};

export default LanguageSwitcher;
