import React from "react";
import { Line } from "react-chartjs-2";
import { useTranslation } from "react-i18next";

const ActionLinechart = ({ history, language }) => {
  const { t, i18n } = useTranslation();
  // Ensure history is sorted by date
  const sortedHistory = history?.sort(
    (a, b) => new Date(a.date) - new Date(b.date)
  );

  const formattedDates = sortedHistory?.map((entry) => {
    const date = new Date(entry.date);
    const options = { day: "numeric" };

    if (i18n.language === "fr") {
      options.month = "long";
      return date.toLocaleDateString("fr-FR", options);
    } else {
      options.month = "long";
      return date.toLocaleDateString("ar-AR", options);
    }
  });

  const percentages = sortedHistory?.map((entry) => entry.currentPourcentage);

  const data = {
    labels: formattedDates,
    datasets: [
      {
        label: t("pourcentageLabel"),
        data: percentages,
        fill: false,
        borderColor: "blue",
        tension: 0.1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: "category",
        labels: formattedDates,
        title: {
          display: true,
          text: t("monthsLabelPlural"),
        },
      },
      y: {
        min: 0,
        max: 100,
        title: {
          display: false,
          text: t("pourcentageLabel"),
        },
        ticks: {
          callback: (value) => {
            return i18n.language === "ar"
              ? `${value.toLocaleString("ar-EG")}%`
              : `${value}%`;
          },
        },
      },
    },
  };


  return (
    <div  style={{ height: "300px" }}>
      <Line data={data} options={options} />
    </div>
  );
};

export default ActionLinechart;
