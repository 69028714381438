import GaugeChart from "react-gauge-chart";

const GlobalTimeGaugeChart = ({value}) => (
  <GaugeChart
    id="gauge-chart4"
    nrOfLevels={1}
    percent={value/100}
    arcPadding={0.1}
    cornerRadius={3}
    textColor={"black"}
    style={{ width: "300px", fontFamily: "Digital-7" }}
    arcWidth={0.2}
    colors={["#00ABE7", "#6900A1"]}
    animate={true}
   />
);

export default GlobalTimeGaugeChart;
