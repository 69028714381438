import React from "react";
import Gauge from "react-gauge-chart";
import { useTranslation } from "react-i18next";

const GaugeChart = ({ value, max, label }) => {
  const { t } = useTranslation();

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          // border: "1px solid #ddd",
          // borderRadius: "8px",
          // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          // border: "1px solid #333",
        }}
      >
        <Gauge
          id="gauge-chart3"
          nrOfLevels={7}
          colors={["black", "red", "orange", "blue", "yellow", "#ABEBC6", "green"]}
          arcWidth={0.2}
          percent={value / 100}
           

          textColor={"black"}
          style={{ width: "100%", fontFamily: "Digital-7" }}
          needleColor={
            value === 0
              ? "black"
              : value <= 15 && value > 0
              ? "red"
              : value <= 25 && value > 15
              ? "orange"
              : value <= 65 && value > 25
              ? "blue"
              : value <= 90 && value > 65
              ? "yellow"
              : (value < 99.999) & (value > 90)
              ? "green"
              : "#ABEBC6"
          }

        />
      </div>
    </>
  );
};
export default GaugeChart;
