import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { Typography } from "@mui/material";
import Header from "../components/Header";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";
import { setAuthorizationHeader, get, post } from "../config/api";
import { ArrowBack, ArrowForwardIos, HomeOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const appBarStyle = {
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "row",
  background: "#007bff",
  color: "#fff",
  width: "92%",
  marginBottom: "20px",
  alignItems: "center",
  padding: "10px",
  borderRadius: "8px",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
};

const iconStyle = {
  fontSize: "24px",
  cursor: "pointer",
  color: "white",
};

const titleStyle = {
  flex: 1,
  textAlign: "center",
  margin: "0 15px",
  color: "#fff",
};

const Activities = () => {
  const { t, i18n } = useTranslation();
  const { sectorId, sectorName } = useParams();
  const navigate = useNavigate();

  const [activities, setActivities] = useState([]);
  const fetchActivities = async () => {
    const accessToken = localStorage.getItem("accessToken");
    setAuthorizationHeader(accessToken);
    try {
      const endpoint = `api/v1/activity/by-sector/${sectorId}`;
      const response = await get(endpoint);
      setActivities(response);
      console.log(response);
    } catch (error) {
      console.error("Failed to fetch  :", error.message);
    }
  };

  useEffect(() => {
    fetchActivities();
    if (localStorage.getItem("accessToken") === "") {
      navigate("/");
    }
  }, []);

  const goBack = () => {
    navigate(-1);
  };

  function generateRandomColor() {
    // Predefined list of colors
    const colors = [
      "#33FF57", // Green
      "#3357FF", // Blue
      "#F333FF", // Magenta
      "#33FFF3", // Cyan
      "#FF5733", // Red
      "#F3FF33", // Yellow
    ];

    // Use a static variable to remember the index of the next color to return
    generateRandomColor.currentIndex = generateRandomColor.currentIndex || 0;

    // Get the current color
    const color = colors[generateRandomColor.currentIndex];

    // Update the index to the next color, cycling back to 0 if necessary
    generateRandomColor.currentIndex =
      (generateRandomColor.currentIndex + 1) % colors.length;

    return color;
  }

  const colorPalette = Array.from({ length: activities.length }, () =>
    generateRandomColor()
  );

  const chartData = {
    labels: activities.map((activity, index) =>
      i18n.language === "ar" ? activity.shortLabelArabic : activity.shortLabel
    ),
    datasets: [
      {
        label: `${t("pourcentageLabel")}`,
        data: activities.map((activity) => activity.pourcentage),
        backgroundColor: colorPalette,
        borderColor: colorPalette,
        borderWidth: 1,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        display: false,
        type: "category",
        title: {
          display: true,
          text: `${t("activityLabel")}`,
        },
      },
      y: {
        type: "linear",
        title: {
          display: true,
          text: `${t("pourcentageLabel")}`,
        },
        min: 0,
        max: 100,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      
    },
    onClick: (event, elements) => {
      if (elements.length > 0) {
        const clickedIndex = elements[0].index;
        const clickedActivity = activities[clickedIndex];
        const activityId = clickedActivity.id;
        const activityName = clickedActivity.label;
        const state = {
          clickedActivity: clickedActivity,
        };

        navigate(`/actions/${activityName}/${sectorName}/${activityId}`, {
          state: state,
        });
      }
    },
  };

  const handleLabelClick = (labelIndex) => {
    const clickedActivity = activities[labelIndex];
    const activityId = clickedActivity.id;
    const activityName = clickedActivity.label;
    const state = {
      clickedActivity: clickedActivity,
    };

    navigate(`/actions/${activityName}/${sectorName}/${activityId}`, {
      state: state,
    });
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Header />
      <div style={appBarStyle}>
        <ArrowForwardIos
          style={{ ...iconStyle, transform: "rotate(180deg)" }}
          onClick={goBack}
        />
        <Typography variant="h6" gutterBottom>
          {t(sectorName)}
        </Typography>
        <Link to="/dashboard" style={{ textDecoration: "none" }}>
          {/* <HomeOutlined style={iconStyle} /> */}
        </Link>
      </div>
      <Bar height={80} data={chartData} options={options} />

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          width: "90%",
        }}
      >
        {chartData.labels.map((label, index) => (
          <Typography
            key={index}
            variant="body2"
            style={{
              marginLeft: "40px",
              cursor: "pointer",
              maxWidth: "200px",
              wordWrap: "break-word",
              fontWeight: "bold",
            }}
            onClick={() => handleLabelClick(index)}
          >
            {label}
          </Typography>
        ))}
      </div>
    </div>
  );
};

export default Activities;
