import React, { useEffect, useState } from "react";
import "../styles/dataResume.css";
import GaugeChart from "./GaugeChart";
import { CircularProgress, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import GlobalTimeGaugeChart from "./GlobalTimeGauge";

const DataResume = ({ percentage, sectors, activities, actions, timePercentage }) => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(true);
  const isArabic = i18n.language === "ar";
  const containerStyle = isArabic
    ? { flexDirection: "row-reverse" }
    : { flexDirection: "row" };

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);
  return (
    <div className="container-resume" style={containerStyle}>
      
      <div className="child-resume-gauche" >
        <GlobalTimeGaugeChart value={timePercentage} />
        <div className="footer">

        <span>{t("TVPD")}</span>
        </div>

      </div>

      <div className="child-resume">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            gap: 20,
          }}
        >
          <div
            className="card-resume"
            style={{ backgroundColor: "white", border: "2px solid #a2b1bf" }}
          >
            <div className="first-child">
              <span>{t("sectors")}</span>
            </div>
            <div className="second-child">
              <div
                style={{
                  borderRadius: "4px",
                  background: "#1976d2",
                  color: "white",
                  fontFamily: "Digital-7",
                  width: "80%",
                  height: "80%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "50px",
                }}
              >
                {loading ? <CircularProgress style={{ color: 'white' }} /> : sectors}
              </div>
            </div>
          </div>
          <div
            className="card-resume"
            style={{ backgroundColor: "white", border: "2px solid #a2b1bf" }}
          >
            <div className="first-child">
              <span>{t("activities")}</span>
            </div>
            <div className="second-child">
              <div
                style={{
                  borderRadius: "4px",
                  background: "#1976d2",
                  color: "white",
                  fontFamily: "Digital-7",
                  width: "80%",
                  height: "80%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "50px",
                }}
              >
                {loading ? <CircularProgress style={{ color: 'white' }} /> : activities}
              </div>
            </div>
          </div>
          <div
            className="card-resume"
            style={{ backgroundColor: "white", border: "2px solid #a2b1bf" }}
          >
            <div className="first-child">
              <span>{t("actions")}</span>
            </div>
            <div className="second-child">
              <div
                style={{
                  borderRadius: "4px",
                  background: "#1976d2",
                  color: "white",
                  fontFamily: "Digital-7",
                   width: "80%",
                  height: "80%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "50px",
                }}
              >
                {loading ? <CircularProgress style={{ color: 'white' }} /> : actions}
              </div>
            </div>
          </div>
        </div>
        <div className="footer">

        <Typography>{t("aggregated_data")}</Typography>
        </div>
      </div>

      <div className="child-resume-gauche" style={{ width: "30%" }}>
        <GaugeChart value={percentage} max={100} label="Completion" />
        <div className="footer">

        <span>{t("global_progress")}</span>
        </div>
      </div>
    </div>
  );
};

export default DataResume;
