import React from 'react';
import 'quill/dist/quill.snow.css';  // Quill styles
import ReactQuill from 'react-quill'; // React wrapper for Quill editor

const RichTextEditor = ({ value, onChange }) => {
  return (
    <ReactQuill
      value={value}
      onChange={onChange}  // Add onChange handler to update state in the parent component
      theme="snow"  // Theme can be set to 'snow' or 'bubble'
    />
  );
};

export default RichTextEditor;
