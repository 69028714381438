import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { Alert, Button, Grid, Paper, TextField } from "@mui/material";
import Header from "../components/Header";
import { baseURL, get, setAuthorizationHeader } from "../config/api";
import useAuth from "../hooks/useAuth";
import "../styles/login.css";
import { globalComment } from "../data/comments";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const { setAuth } = useAuth();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/dashboard";
  const emailRef = useRef();
   const passwordRef = useRef();
  const errRef = useRef();

   
  

  useEffect(() => {
    setError("");
  }, [email, password]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${baseURL}/api/v1/auth/authenticate`, {
        email,
        password,
      });
      console.log(response);
      const accessToken = response?.data?.access_token;
      const role = response?.data?.role;
      const sector = response?.data?.sector;
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("role", role);
      localStorage.setItem("hendah", sector);

      localStorage.setItem("gl", globalComment);
      localStorage.setItem("sa", "");
      localStorage.setItem("ag", "");
      localStorage.setItem("sa", "");
      localStorage.setItem("hy", "");
      localStorage.setItem("el", "");
      localStorage.setItem("ed", "");
      
      setAuth({ email, password, role, accessToken });
      setEmail("");
      setPassword("");
      navigate(from, { replace: true });
    } catch (err) {
      console.log("==>", err);
      if (!err?.response) {
        setError("No Server Response");
      } else if (err.response?.status === 400) {
        setError("Missing Username or Password");
      } else if (err.response?.status === 401) {
        setError("Unauthorized");
      } else {
        setError("Login Failed");
      }
      errRef.current.focus();
    }
  };

  const paperStyle = {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    padding: 20,
    height: error ? "55vh" : "45vh",
    width: 280,
    margin: "20px auto",
  };

  return (
    <div className="login-container">
      <div className="login-header">
        <Header header={false}/>
      </div>
      <Grid>
        <Paper elevation={10} style={paperStyle}>
          <Grid align="center">
            <h2>Authentification</h2>
          </Grid>
          {error && (
            <Alert ref={errRef} severity="error">
              error
            </Alert>
          )}
          <form
            style={{
               height: "60%",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
            onSubmit={handleSubmit}
          >
            <TextField
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              label="Utilisateur"
              placeholder="Entrez nom d'utilisateur"
              variant="outlined"
              fullWidth
              ref={emailRef}
              autoFocus  
              required
            />
            <TextField
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              label="Mot de passe"
              placeholder="Entrez le mot de passe"
              type="password"
              variant="outlined"
              fullWidth
              ref={passwordRef}
              required
            />
            <Button
              style={{ textTransform: "none" }}
              type="submit"
              color="primary"
              variant="contained"
              fullWidth
              // onClick={handleSubmit}
            >
              Connecter
            </Button>
          </form>
        </Paper>
      </Grid>
    </div>
  );
};

export default Login;
