import * as React from "react";
import { Pie } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import 'chartjs-plugin-datalabels';


const PieActiveArc = ({ data }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const chartData =
    data &&
    data?.map((section) => ({
      id: section.id,
      value: section.pourcentage,
      label: `${
        i18n.language === "ar" ? section.labelArabic : section.label
      } (${section.pourcentageTA.toFixed(2)}%)`,
    }));

  const handleChartClick = (elements) => {
    if (elements && elements[0]) {
      const clickedElement = elements[0];
      const selectedSection = data[clickedElement.index];
      if (selectedSection) {
        navigate(`/activities/${selectedSection.label}/${selectedSection.id}`);
      }
    }
  };

  const chartOptions = {
    onClick: (_, elements) => handleChartClick(elements),
    
    // plugins: {
    //   legend: {
    //     position: 'top',
    //      labels: {
    //       usePointStyle: true,
    //       pointStyle: 'circle',
           
    //     }
    //   },
    // },
    plugins: {
      datalabels: {
         formatter: (value, context) => {
          const section = data[context.dataIndex];
          return `${section.label} (${section.pourcentageTA.toFixed(2)}%)`;
        },
        
         borderColor: '#000043',  
       },
    },
     
  };

  const chartConfig = {
    type: "pie",
    data: {
      datasets: [
        {
          data: chartData.map((item) => item.value),
          backgroundColor: [
            "#7FB3D5",
            "#85C1E9",
            "#424949",
            "#ABEBC6",
            "#99A3A4",
            "#BB8FCE",
            "#F2F4F4",
          ],
          label:t('ofBudget')
        },
      ],
      labels: chartData.map((item) => item.label),
      
    },
  };

  return <Pie  data={chartConfig.data} options={chartOptions} height={10} />;
};

export default PieActiveArc;
