import React from "react";
import { useTranslation } from "react-i18next";

const Legend = () => {
  const { t, i18n } = useTranslation();

  const legendContainerStyle = {
    position: "absolute",
    top: "5%",
    right: "0",
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "5px",
    zIndex: 1000,
    border: "1px solid #ddd",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    fontFamily:"Digital-7"
  };

  const legendTitleStyle = {
    fontSize: "12px",
    fontWeight: "bold",
    backgroundColor: "blue",
    color: "white",
    position: "absolute",
    top: 0,
    right: "0",
    borderRadius: "5px 5px 0 0",
    zIndex: 1000,
    border: "1px solid #ddd",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    width:  "14.3%" ,
    padding: "5px",
    alignItems:"center",
    display:'flex',
    justifyContent:"center"
  };

  const legendItemStyle = {
    display: "flex",
    alignItems: "center",
    marginBottom: "8px",
    cursor: "pointer",
  };

  const legendDotStyle = {
    width: "12px",
    height: "12px",
    borderRadius: "50%",
    marginRight: "8px",
  };

  const legendLineStyle = {
    borderTop: "1px solid #ddd",
    margin: "8px 0",
  };

  return (
    <>
      <div style={legendTitleStyle}>{t("Legend Title")}</div>
      <div style={legendContainerStyle}>
        {/* <div style={legendItemStyle}>
                <div style={{ ...legendDotStyle }}></div>
                <span>{t('all')}</span>
            </div>
            <div style={legendLineStyle}></div> */}

        <div style={legendItemStyle}>
          <div style={{ ...legendDotStyle, backgroundColor: "black" }}></div>
          <span>0%</span>
        </div>
        <div style={legendLineStyle}></div>
        <div style={legendItemStyle}>
          <div style={{ ...legendDotStyle, backgroundColor: "red" }}></div>
          <span>0.01-15%</span>
        </div>
        <div style={legendLineStyle}></div>
        <div style={legendItemStyle}>
          <div style={{ ...legendDotStyle, backgroundColor: "orange" }}></div>
          <span>15-25%</span>
        </div>
        <div style={legendLineStyle}></div>
        <div style={legendItemStyle}>
          <div style={{ ...legendDotStyle, backgroundColor: "blue" }}></div>
          <span>25-65%</span>
        </div>
        <div style={legendLineStyle}></div>
        <div style={legendItemStyle}>
          <div style={{ ...legendDotStyle, backgroundColor: "yellow" }}></div>
          <span>65-90%</span>
        </div>
        <div style={legendLineStyle}></div>
        <div style={legendItemStyle}>
          <div style={{ ...legendDotStyle, backgroundColor: "#ABEBC6" }}></div>
          <span>90%-99.99%</span>
        </div>
        <div style={legendLineStyle}></div>
        <div style={legendItemStyle}>
          <div style={{ ...legendDotStyle, backgroundColor: "green" }}></div>
          <span>100%</span>
        </div>
      </div>
    </>
  );
};

export default Legend;
