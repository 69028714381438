import React from "react";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const {t,i18n} =  useTranslation();
  return (
    <footer
      style={{
        position: "fixed",
        bottom: "0",
        width: "100%",
        height: "24px",  
        textAlign: "center",
        backgroundColor: "#000043",
        fontSize: "10px",
        fontWeight:"bold",  
        color: '#fff',  
        borderTop: "1px solid #ccc",  
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        }}
    >
      <p style={{ margin: "0", fontFamily: 'Arial, sans-serif' }}>
        {t('copyright')}
      </p>
    </footer>
  );
};

export default Footer;
