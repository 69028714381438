import { Add, Edit } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { post, setAuthorizationHeader } from "../config/api";
import { borderRadius, padding } from "@mui/system";


const styles = {
  dialogTitle: {
    background: "#000043",
    color: "#fff",
  },
  dialogContent: {
    padding: "20px",
  },
  dialogActions: {
    padding: "20px",
    justifyContent: "space-between",
  },
  addButton: {
    border: "1px solid #000043",
    color: "#000043",
    padding: "10px 20px",
    borderRadius: "5px",
    textTransform: "none",
  },
  editButton: {
    fontSize: "12px",
    color: "#000043",
  },
};

const AvcBarChart = ({ avcs, activityId, fetchAVC }) => {
  const [render, setRender] = useState(false);
  const [currentAvc, setCurrentAvc] = useState({});
  const [newPercentage, setNewPercentage] = useState(0);
  const [avcId, setAvcId] = useState(0);
  const [label, setLabel] = useState("");
  const [percentage, setPercentage] = useState(0);

  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);


  const handleOpenUpdateDialog = (avc) => {
    setCurrentAvc(avc);
    setNewPercentage(avc.percentage);
    setAvcId(avc.id);
    setUpdateDialogOpen(true);
  };

  const handleOpenCreateDialog = () => {
    setCreateDialogOpen(true);
    setLabel("");
    setNewPercentage(0);
  };

  const handleCloseDialog = () => {
    setUpdateDialogOpen(false);
    setCreateDialogOpen(false);
    setAvcId(0);
  };

  const handleUpdate = async () => {
    const accessToken = localStorage.getItem("accessToken");
    setAuthorizationHeader(accessToken);
    try {
      const endpoint = `/api/v1/avcs/update/${avcId}`;
      const body = {
        newPercentage: newPercentage,
      };
      const response = await post(endpoint, body);
      setUpdateDialogOpen(false);
      setRender(!render);
       return response.data;
    } catch (error) {
      console.error("Failed to update jalon:", error);
      throw error;
    }
  };

  const handleCreate = async () => {
    const accessToken = localStorage.getItem("accessToken");
    setAuthorizationHeader(accessToken);
    try {
      const endpoint = `/api/v1/avcs`;
      const body = {
        label: label,
        percentage: percentage,
        activityId: activityId,
      };
      const response = await post(endpoint, body);
      setCreateDialogOpen(false);
      setRender(!render);
      return response.data;
    } catch (error) {
      console.error("Failed to update jalon:", error);
      throw error;
    }
  };

  const getBarStyle = (percentage) => ({
    width: `${percentage}%`,
    backgroundColor: `hsl(${Math.random() * 360}, 70%, 60%)`,
    color: "white",
    textAlign: "right",
    fontFamily: "Digital-7",
    borderRadius:"10px",
    padding:"0 5px 0 0"
    
  });

  useEffect(() => {fetchAVC();}, [render]);

  return (
    <>
      <div
        style={{
          width: "100%",
          borderBottom: "1px solid #000043",
          // padding: "10px",
          marginBottom:"2%  "
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
            padding: "10px",
          }}
        >
          <Divider style={{ width: "38%", color: "#000043" }} />

          <Button
            variant="outlined"
            startIcon={<Add />}
            onClick={() => handleOpenCreateDialog()}
          >
            Ajouter | jalon
          </Button>
          <Divider style={{ width: "38%" }} />
        </div>

        {avcs.length > 0 &&
          avcs.map((avc, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "10px",
                width: "90%",
                marginLeft: "5%",
              }}
            >
              <div
                style={{ width: "100%", display: "flex", alignItems: "center" }}
              >
                <div style={{ marginRight: "10px" }}>{avc.label}</div>
                <div style={getBarStyle(avc.percentage)}>{avc.percentage}%</div>
              </div>
              <Button
                variant="outlined"
                startIcon={<Edit style={{width:'15px', height:'15px'}} />}
                onClick={() => handleOpenUpdateDialog(avc)}
                style={{fontSize:"8px"}}
              >
                 Modifier
              </Button>
            </div>
          ))}
      </div>

      <Dialog
        maxWidth="lg"
        open={updateDialogOpen}
        onClose={handleCloseDialog}
      >
        <DialogTitle className={styles.dialogTitle}>Modifier</DialogTitle>
        <DialogContent className={styles.dialogContent}>
          <TextField
            margin="dense"
            label="Pourcentage"
            type="number"
            fullWidth
            variant="outlined"
            value={newPercentage}
            onChange={(e) => setNewPercentage(e.target.value)}
          />
        </DialogContent>
        <DialogActions className={styles.dialogActions}>
          <Button onClick={handleCloseDialog}>Annuller</Button>
          <Button onClick={handleUpdate}>Mettre à jour</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={createDialogOpen} onClose={handleCloseDialog}>
        <DialogTitle className={styles.dialogTitle}>
          Ajouter un nouveau jalon
        </DialogTitle>
        <DialogContent className={styles.dialogContent}>
          <TextField
            margin="dense"
            label="Label"
            type="text"
            fullWidth
            variant="outlined"
            value={label}
            onChange={(e) => setLabel(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Pourcentage"
            type="number"
            fullWidth
            variant="outlined"
            value={percentage}
            onChange={(e) => setPercentage(e.target.value)}
          />
        </DialogContent>
        <DialogActions className={styles.dialogActions}>
          <Button onClick={handleCloseDialog}>Annuller</Button>
          <Button onClick={handleCreate}>Creer</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AvcBarChart;
