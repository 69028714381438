import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  PDFDownloadLink,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import { CloseOutlined, Download } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextareaAutosize,
} from "@mui/material";
import RichTextEditor from "./RitchText";
import { get, post, setAuthorizationHeader } from "../config/api";
import { useNavigate } from "react-router-dom";
 import { Html } from "react-pdf-html";
  
 Font.register({
  family: 'Roboto',
  src: 'https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap',
});

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 20,
    color: '#333', // Dark grey for text for better readability
  },
  header: {
    marginBottom: 20,
    textAlign: 'center',
  },
  logoContainer: {
    marginTop: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    width: 150,
    height: 150,
  },
  content: {
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 40,
  },
  footer: {
    position: 'absolute',
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: 'center',
    fontSize: 12,
  },
  sectorTable: {
    marginBottom: 20,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#ccc', // Light grey border for the tables
  },
  headerRow: {
    flexDirection: 'row',
    backgroundColor: '#f0f0f0', // Light grey background for header
    color: '#000', // Black color for header text
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
  },
  row: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: '#ccc',
  },
  cell: {
    padding: 5,
    fontSize: 12,
  },
  sectorComment: {
    marginVertical: 10,
    fontSize: 12,
    textAlign: 'justify',
    lineHeight: 1.5,
  },
  labelCommentContainer: {
    marginBottom: 10,
  },
  labelComment: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  globalCommentContainer: {
    marginBottom: 20,
    fontSize: 12,
  },
  textheader3: {
    fontSize: 16,
    marginBottom: 10,
    textAlign: 'center',
  },
  sectorLabel: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  date: {
    fontSize: 12,
    textAlign: 'right',
    marginTop: 10,
  },
});



const pdfStyles = StyleSheet.create({
  body: {
    fontFamily: 'Roboto',
    fontSize: 10,
  },
  em: {
    fontStyle: 'italic',
  },
  u: {
    textDecoration: 'underline',
  },
  ol: {
    marginLeft: 20,
  },
  li: {
    marginBottom: 5,
  },
  p: {
    margin: 5,
    textAlign: 'justify',
    lineHeight: 1.5,
  },
  br: {
    margin: 10,
  }
});


const SectorTable = ({ sector, sectorComments }) => {
  const getCommentForSector = (sectorLabel) => {
    if (sectorComments && sectorComments[sectorLabel]) {
      return sectorComments[sectorLabel];
      // return htmlFrom(sectorComments[sectorLabel]);
    }
    return "";
  };

  return (
    <View style={styles.sectorTable}>
      <View style={styles.headerRow}>
        <Text style={[styles.headerCell2, { flex: 1 }]}>
          {sector.label} ({sector.pourcentageTA.toFixed(2)}%)
        </Text>
        <Text style={[styles.headerCell3, { flex: 1 }]}> CRITIQUE</Text>
      </View>
      <Text style={styles.sectorComment}>
        <Html
           stylesheet={pdfStyles.body}
           customCSSTranslator={(tag, style) => {
            // This function allows for additional style adjustments dynamically
            switch (tag) {
              case "br":
                return { marginTop: 10, marginBottom: 10 };  
              case "p":
                return { ...style, marginBottom: 10, marginTop: 10 };  
              case "ol":
                return { ...style, marginLeft: 20 };  
              case "li":
                return { ...style, marginLeft: 20, marginBottom: 10, };  
              case "u":
                return { ...style,  textDecoration: 'underline', fontSize:5 };  
              case "em":
                return { ...style,  fontStyle: 'italic', fontSize:5 };  
              default:
                return style;
            }
          }}
        >
          {getCommentForSector(sector.label)}
        </Html>
      </Text>
      <View style={styles.headerRow}>
        <Text
          style={[
            styles.headerCell,
            { flex: 1, borderRightWidth: 1, borderRightColor: "#000" },
          ]}
        >
          Activité(s)
        </Text>
        <Text style={[styles.headerCell, { flex: 1 }]}>Taux D'avancement</Text>
      </View>
      {sector.activities?.map((activity) => (
        <View style={styles.row} key={activity.label}>
          <Text
            style={[
              styles.cell,
              { flex: 1, borderRightWidth: 1, borderRightColor: "#000" },
            ]}
          >
            {activity.label}
          </Text>
          <Text style={[styles.cell, { flex: 1 }]}>
            {activity.pourcentage.toFixed(2)}%
          </Text>
        </View>
      ))}
    </View>
  );
};

const MyDocument = ({ t, sectors, globalComment, sectorComments }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.header}>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <View style={[styles.logoContainer, { width: "20%" }]}>
            <Image style={styles.logo} src="/assets/logo_rim.png" />
          </View>
          <View style={[styles.logoContainer, { width: "70%" }]}>
            <Image style={styles.slogan} src="/slogan_rim.png" />
          </View>
        </View>
      </View>
      <View
        style={{
          borderBottomWidth: 8,
          borderBottomColor: "#00A95C",
          marginBottom: 1,
        }}
      />
      <Text style={styles.textheader3}>{t("MEDD")}</Text>
      <View
        style={{
          borderBottomWidth: 8,
          borderBottomColor: "#00A95C",
          marginBottom: 1,
        }}
      />
      <View style={styles.content}>
        <Text style={styles.sectorLabel}>{t("Note de Suivi")}</Text>
        <Text style={styles.sectorLabel}>{t("projectLabel")}</Text>
        <Text style={styles.date}>{new Date().toLocaleDateString()}</Text>
      </View>
      <View style={styles.footer}>
        <Text
          style={{
            fontSize: 10,
            borderBottomWidth: 4,
            borderBottomColor: "#00A95C",
          }}
        >
          <Image src="/assets/copyright.png" />
          {t("copyright")}{" "}
        </Text>
      </View>
    </Page>

    <Page size="A4" style={styles.page}>
      <View style={styles.labelCommentContainer}>
        <Text style={styles.labelComment}>{t("GC")}</Text>
      </View>
      <View style={styles.globalCommentContainer}>
        <Text
          style={{
            fontSize: 10,
          }}
        >
          <Html>{globalComment}</Html>
        </Text>
      </View>
      {sectors?.map((sector, index) => (
        <SectorTable
          key={index}
          sector={sector}
          sectorComments={sectorComments}
        />
      ))}
      <View style={styles.footer}>
        <Text
          style={{
            fontSize: 8,
            borderBottomWidth: 4,
            borderBottomColor: "#00A95C",
          }}
        >
          <Image src="/assets/copyright.png" />
          {t("copyright")}
        </Text>
      </View>
    </Page>
  </Document>
);

const Header = ({ sectors, header }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [sectorComments, setSectorComments] = useState({});
  const [globalComment, setGlobalComment] = useState("");
  const [loading, setLoading] = useState(false);

  const fetchComments = async () => {
    const accessToken = localStorage.getItem("accessToken");
    setAuthorizationHeader(accessToken); // Ensure the authorization header is set for the request
    try {
      const response = await get("api/v1/report"); // Fetch comments from the backend
      const commentsByRef = {};

      // Map comments by their reference
      response.forEach((comment) => {
        commentsByRef[comment.commentReference] = comment.comment;
      });

       setGlobalComment(commentsByRef[10] || "");

       const initialSectorComments = sectors.reduce((acc, sector) => {
        acc[sector.label] = commentsByRef[sector.id] || ""; // Use sector ID to find the corresponding comment
        return acc;
      }, {});

      setSectorComments(initialSectorComments); // Set sector-specific comments
    } catch (error) {
      console.error("Failed to fetch comments:", error);
     }
  };

  const updateComment = async (commentReference, newCommentText) => {
    try {
      const endpoint = `/api/v1/report/${commentReference}`;
      const body = {
        comment: newCommentText,
      };
      const response = await post(endpoint, body);
      return response.data;
    } catch (error) {
      console.error("Failed to update comment:", error);
      throw error;
    }
  };

  const updateAllComments = async () => {
    setLoading(true);

    await updateComment(10, globalComment); // Update global comment

    const updatePromises = sectors.map((sector) => {
      const commentText = sectorComments[sector.label];
      if (commentText) {
        return updateComment(sector.id, commentText);
      }
      return Promise.resolve();
    });

    try {
      await Promise.all(updatePromises);
      console.log("All comments updated successfully");
    } catch (error) {
      console.error("Error updating comments:", error);
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchComments();
  }, [sectors]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCommentChange = (sectorLabel, newValue) => {
    setSectorComments((prevComments) => {
      console.log(`Updating comment for ${sectorLabel}: ${newValue}`);
      return { ...prevComments, [sectorLabel]: newValue };
    });
  };

  useEffect(() => {
    console.log("Component has re-rendered.");
  }, [sectorComments]);

  const LoadingOverlay = ({ isLoading }) => {
    if (!isLoading) return null;
    return (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0,0,0,0.5)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1000,
        }}
      >
        <div>Loading...</div>
      </div>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
          marginBottom: "20px",
        }}
      >
        {header && (
          <>
            <button
              style={{
                fontSize: "15px",
                padding: "10px 15px",
                fontWeight: "bold",
                borderRadius: "5px",
                border: "none",
                background: "#007bff",
                color: "#fff",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                transition: "all 0.3s ease",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              }}
              onClick={handleOpen}
            >
              <Download style={{ marginRight: "5px" }} />
              {t("export")}
            </button>
          </>
        )}
      </div>

      <LoadingOverlay isLoading={loading} />

      <Dialog
        open={open}
        fullWidth={true}
        onClose={handleClose}
        maxWidth="md"
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle
          id="scroll-dialog-title"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          {t("dialog_add_comment")}
          <CloseOutlined style={{ cursor: "pointer" }} onClick={handleClose} />
        </DialogTitle>
        <DialogContent
          dividers={true}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
          }}
        >
          {/* Global Comment Section */}
          <h3>{t("GC")}</h3>
          <RichTextEditor
            value={globalComment}
            onChange={(value) => setGlobalComment(value)}
            style={{ width: "100%", minHeight: "100px" }}
          />

          {/* Sectors Comment Section */}
          <div
            style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
          >
            {sectors?.map((sector, index) => (
              <div
                key={sector.label}
                style={{
                  width: "50%",
                  paddingRight: "10px",
                }}
              >
                <h3>
                  {i18n.language === "ar" ? sector.labelArabic : sector.label}
                </h3>
                <RichTextEditor
                  value={sectorComments[sector.label]}
                  onChange={(value) => handleCommentChange(sector.label, value)}
                  placeholder={t("Add_Comment")}
                  style={{ width: "100%", minHeight: "80px" }}
                />
              </div>
            ))}
          </div>
        </DialogContent>
        <DialogActions style={{ justifyContent: "right" }}>
          <PDFDownloadLink
            document={
              <MyDocument
                t={t}
                sectors={sectors}
                globalComment={globalComment}
                sectorComments={sectorComments}
              />
            }
            fileName="rapport_pshc_2024.pdf"
          >
            {({ blob, url, loading, error }) => (
              <Button
                onClick={async () => {
                  await updateAllComments();
                }}
                variant="contained"
                color="primary"
                style={{
                  textTransform: "none",
                  fontWeight: "bold",
                  backgroundColor: "#00043",
                  color: "white",
                }}
                disabled={loading}
              >
                {loading ? t("exporting") : t("export")}
              </Button>
            )}
          </PDFDownloadLink>
        </DialogActions>
      </Dialog>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          textAlign: "center",
        }}
      >
        <img
          src="/assets/logo_rim.png"
          alt="Logo"
          style={{ width: "11%", height: "11%", marginBottom: 10 }}
        />
        <div>
          <img
            src="/assets/slogan_rim.png"
            alt="Slogan"
            style={{ width: "32%", height: "7%", marginBottom: 10 }}
          />
        </div>
        <span
          style={{
            display: "block",
            fontSize: "18px",
            padding: "5px",
            width: "100%",
            fontWeight: "bold",
            fontFamily: "Consolas, monospace",
            textDecoration: "underline",
          }}
        >
          {t("projectLabel")}
        </span>
      </div>
    </div>
  );
};

export default Header;
 